/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRSpinner, useAuth, useGetMediaItem } from '@SLR/shared-library';
import { GlobalErrorResponse, ResponseError } from '@SLR/user-service-full-sdk';
import { ErrorModal } from 'components';
import { APP_URL, INVITATION_HANDLE } from 'configs';
import {
  useAcceptInvitation,
  useGetCurrentUserProfile,
  useGetOrganization
} from 'hooks';
import { useQueryParam } from 'providers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Handle, InvitationModal, Success } from '.';

import './Invitation.scss';

export const InvitationHandlePage: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'invitationsPage'
  });

  const { logout } = useAuth();

  const { invitationQuery } = useQueryParam();
  const {
    token,
    organizationId = '',
    acceptImmediately
  } = invitationQuery ?? {};

  const {
    mutate: acceptInvitation,
    isPending: isInvitationLoading,
    isError: isInvitationError
  } = useAcceptInvitation();

  const {
    data: organization,
    isPending: isOrganizationLoading,
    isError: isOrganizationError
  } = useGetOrganization(organizationId);

  const {
    data: user,
    isPending: isUserLoading,
    isError: isUserError
  } = useGetCurrentUserProfile();

  const { data: profilePicture, isFetching: isProfilePictureLoading } =
    useGetMediaItem(user?.pictureId);

  const [showHandleModal, setShowHandleModal] = useState<boolean>(true);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>(t('loadingError'));

  const handleAcceptWithCurrentAccount = useCallback(() => {
    if (token && organizationId) {
      acceptInvitation(
        { organizationId, token },
        {
          onSuccess: () => {
            setShowHandleModal(false);
            setShowSuccessModal(true);
          },
          onError: async (error) => {
            const ex: GlobalErrorResponse = await (
              error as ResponseError
            ).response.json();
            setShowHandleModal(false);
            setErrorMessage(`${t('failedAccept')} (Fehlercode: ${ex.traceId})`);
          }
        }
      );
    }
  }, [token, organizationId, acceptInvitation, t]);

  useEffect(() => {
    if (acceptImmediately) {
      handleAcceptWithCurrentAccount();
    }
    // eslint-disable-next-line
  }, [acceptImmediately]);

  const handleAcceptWithDifferentAccount = () => {
    const redirectUri = `${APP_URL}${INVITATION_HANDLE}?organizationId=${organizationId}&token=${token}&acceptImmediately=true`;
    logout(redirectUri);
  };

  const isLoading = useMemo(
    () =>
      isOrganizationLoading ||
      isInvitationLoading ||
      isUserLoading ||
      isProfilePictureLoading,

    [
      isInvitationLoading,
      isOrganizationLoading,
      isProfilePictureLoading,
      isUserLoading
    ]
  );

  const isError = useMemo(
    () => isOrganizationError || isInvitationError || isUserError,
    [isInvitationError, isOrganizationError, isUserError]
  );

  return (
    <Container className="invitation" fluid>
      {isLoading && <SLRSpinner variant="light" />}
      {showHandleModal && organization && user && (
        <InvitationModal title={t('handle.title')}>
          <Handle
            userName={`${user.firstName} ${user.lastName}`}
            userEmail={user.emailAddress}
            userPicture={profilePicture?.url}
            userPictureAlt={profilePicture?.altText}
            organizationName={organization?.name}
            onCurrentAccountAcceptClick={handleAcceptWithCurrentAccount}
            onDifferentAccountAcceptClick={handleAcceptWithDifferentAccount}
          />
        </InvitationModal>
      )}
      {showSuccessModal && organization && (
        <InvitationModal title={t('success.title')}>
          <Success
            organizationId={organizationId}
            organizationName={organization.name}
            organizationType={organization.type}
          />
        </InvitationModal>
      )}
      {isError && <ErrorModal errorMessage={errorMessage} />}
    </Container>
  );
};
